import React from 'react';
import '../Assets/Style.css';
import Slider from './Views/Slider'
import Donation from '../Pages/Views/Donat'
import FundraiseList from '../Pages/Views/FundraiseList';
import '../Assets/RetroStyle.css'; 
import MyMarqueeComponent from '../Pages/Views/Marquee';

function Home() {
  return (
    <div>
        <Slider/>
        <MyMarqueeComponent />
   
        <div>
        <div className="site-section section-counter py-5">
            <div className="container">
            <div className="row">
                
                <div className="col-md-12 welcome-text">
                <h2 className="display-4 mb-3">Who Are We?</h2>
                <p className="lh-sm text-justify">
                Jammu Province People's Forum (JPPF) was formed on 11 May 2010 by almost all the well-known organizations of Jammu keeping 
                in mind the welfare and progress of the residents of Jammu Province and to unite and strengthen the voice of Jammu state. 
                These organizations recognized that the two regions of Jammu and Kashmir have distinct issues and solutions due to differences 
                in geography, lifestyle, and other influences. Political parties often prioritize their own interests, neglecting the common 
                people. Today, the residents of Jammu feel helpless and weak, unsure of how to improve their situation. What needed is a strong, 
                selfless organization that can provide direction and address issues such as youth employment, women's security, tourism 
                development, and the elimination of corruption and discrimination. The main objective should be to neutralize anti-national 
                forces while maintaining peaceful conduct and brotherhood in Jammu and Kashmir.
                </p>
                
                <p className="mb-0">
                    <a href="about" className="btn btn-secondary px-3 py-2 ">Learn More</a>
                </p>
                </div>
            </div>
            </div>
        </div>

        <div className="site-section border-top py-5">
            <div className="container">
            <div className="row mb-3 justify-content-center">
          <div className="col-md-8 text-center">
            <h2 className='font-weight-bold'>Latest Cause</h2>
            
          </div>
        </div>
            <div className="row">
            
                <div className="col-md-4">
                <div className="contact-info">
                    <div className="media block-6">
                        <div className="media-body">
                            <h3 className="heading section-title">PLANTATION</h3>
                            <p>If you cut a tree, you kill a life. If you save a tree, you save a life.
                            If you plant a tree, you plant a life.</p>
                            <p><a href="/plantation" className="link-underline">Learn More</a></p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="col-md-4">
                <div className="contact-info">
                    <div className="media block-6">
                        <div className="media-body">
                        <h3 className="heading section-title">CLEAN JAMMU</h3>
                        <p>Help Us to Make your Jammu Clean and Neat</p>
                        <p><a href="/cleanliness" className="link-underline">Learn More</a></p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="col-md-4">
                <div className="contact-info">
                    <div className="media block-6">
                        <div className="media-body">
                            <h3 className="heading section-title">KAPDA BANK</h3>
                            <p>We will Help you to Donat your Cloths to the Right Place</p>
                            <p><a href="/kapadabank" className="link-underline">Learn More</a></p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        {/* <FundraiseList /> */}
        </div>
    </div>
  );
}

export default Home;