import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/Style.css';
import { Link } from 'react-router-dom';


function Membership() {
  return (
    <div className="site-section about-us">
      <div className="container mt-5">
        <div className="row mb-5">
        <div className="row mb-5">
          <div className="col-md-12 text-center border section-header">
            <h2>MEMBERSHIP</h2>
            <div className="col-md-12 text-left">
            <h4 class="text-black font-weight-bold">Eligibility for basic membership</h4>
            <ol type='a' className='lh-sm text-justify fs-6'>
              <li>The membership of the forum is open to any person resident of Jammu Province who has attained the age of maturity (above 18 years).</li>
              <li>The person who believes in the aim and objects of the JPPF and pledges to abide by its constitution.</li>
              <li>The person who subscribes the laid down membership fee.</li>
            </ol>
            <br></br>
            
            <h4 class="text-black font-weight-bold">Membership of the Forum</h4>
            <ol type='a' className='lh-sm text-justify fs-6'>
              <li><a class="font-weight-bold text-secondary">Founder Members:</a>Members who have founded the forum and carried out its activites to date. List framed and enclosed forth with which shall remain a reference to be appended with the constitution book.</li>
              <li><a class="font-weight-bold text-secondary">Associate Members:</a>All Registered social Organization shall be admitted in the forum. Each such organization shall be represented by 03 (three) of its executive member so named. Such organization shall retain their individual identity and will cooperate in activities common to people of Jammu Province.</li>
              <li><a class="font-weight-bold text-secondary">Individual Members:</a>Individuals who choose to be active with the Forum.</li>
              <li><a class="font-weight-bold text-secondary">Opted Members (Hony):</a>Individuals with reputation as artists, Social leaders Retired teachers, Doctors, Judicial officers, Engineers, Businessman, Players etc. whom forum thinks to be useful in one way or the other, shall be co-opted as members (Hony).</li>
            </ol>

            <br></br>
            <h4 class="text-black font-weight-bold">Membership Fee</h4>
            <ol type='a' className='lh-sm text-justify fs-6 '>
              {/* <li>All founder members shall pay a sum of ₹2000 in one time during his life period funds of the forum including membership fee.</li> */}
              <li>Each associate members shall pay life membership of ₹2000 or tenure of two years membership of ₹300.</li>
              <li>Organizations shall pay membership fee of ₹550 per year.</li>
              <li>Individual members shall pay membership fee of ₹100 per year.</li>
              <li>Student shall pay membership fee of ₹55 per year.</li>
              <li>No fee shall be chargeable from Honorary members. However their voluntary contribution shall be welcome</li>
              <li>The person thus enrolled as member of the forum shall be subject to the approval of the President.</li> 
              <li>No defaulter in the payment of basic membership shall be eligible to contest or cast vote in the elections to the post of office bearers of the forum. No members shall be eligible to contest election for any post of the forum unless he/she is registered member of the forum for the last one year. The basic constitution units will be raised with the approval from central executive committee. Each unit will have a committee of not less than 15 (fifteen) members.</li>
              <li>Finances: All membership fees, contribution etc shall be received against proper receipt signed by office secretary. The office secretary shall deposit all such receipts in a scheduled bank where funds of the forum shall be operated upon through cheque book by the President of the Forum jointly with the signatures of secretary general or Finance Secretary. Funds withdrawal and expenditure shall be maintained by Finance secretary. However expenditure shall be incurred by the Sr. Vice President /Secretary General who will verify all expenditure vouchers.</li>
            </ol>
            <p>*<i>The fees mentioned above may be subject to change based on decisions made by the JPPF.</i></p>

            <br></br>
            <p className="mb-0">
              <Link to="/membershipform" className="btn font-weight-bold btn-secondary btn-apply px-3 py-2">
                APPLY NOW
              </Link>                </p>
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
